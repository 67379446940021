import { IEvent } from '../types/Event';
import { MeetingType } from '../types/meetingType';

export const loadMeetings = async (): Promise<MeetingType[] | null> => {
  const resp = await fetch('/data/meetings.json');
  const data = await resp.json();
  return data.meetings as MeetingType[];
};

export const meetingsToEventsMapper = (meetings: MeetingType[] | null): IEvent[] => {
  const events: IEvent[] =
    meetings?.map((meeting) => {
      return {
        id: meeting.id,
        title: meeting.title,
        start: new Date(meeting.start_date),
        end: new Date(meeting.end_date),
        group_color: meeting.group_color,
      };
    }) || [];
  return events;
};

export const getMeetingById = (meetings: MeetingType[] | null, id: string): MeetingType | null => {
  return meetings?.find((meeting) => meeting.id === id) || null;
};

export const getUniqueGroupNamesWithIds = async (): Promise<{ id: string; name: string }[]> => {
  const meetings = await loadMeetings();
  const groupNames = meetings?.map((meeting) => ({ id: meeting.group_id, name: meeting.group_name })) || [];

  return Array.from(new Set(groupNames.map((group) => group.id))).map(
    (id) => groupNames.find((group) => group.id === id) as { id: string; name: string }
  );
};

export const getUniqueLocations = async (): Promise<string[]> => {
  const meetings = await loadMeetings();
  const locations = meetings?.map((meeting) => meeting.address?.city).filter((location): location is string => location !== undefined) || [];

  return Array.from(new Set(locations));
};

export const getMeetingByGroupId = async (groupId: string): Promise<MeetingType[] | null> => {
  const meetings = await loadMeetings();
  return meetings?.filter((meeting) => meeting.group_id === groupId) || null;
};

export const getMeetingByGroupIdAndMeetingId = async (groupId: string, meetingId?: string): Promise<MeetingType | null> => {
  const meetings = await loadMeetings();
  return meetings?.find((meeting) => meeting.group_id === groupId && meeting.id === meetingId) || null;
};

export const getPartnerEvent = async (groupId: string, meetingId?: string): Promise<MeetingType | null> => {
  const meetings = await getMeetingByGroupId(groupId);

  if (meetingId !== undefined) {
    return meetings?.find((meeting) => meeting.group_id === groupId && meeting.id === meetingId) || null;
  } else {
    const currentDate = new Date();
    const closestDateEvent = meetings
      ?.filter((meeting) => new Date(meeting.start_date) > currentDate)
      .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())[0];

    return closestDateEvent ?? null;
  }
};

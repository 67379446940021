import React from 'react';
import { Label, StyledInput, Wrapper } from './StepsContent/SignUpForm';
import styled from 'styled-components';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

function Input({
  type,
  name,
  label,
  placeholder,
  value,
  required = false,
  onChange,
  className,
  onChangePhone,
  error = false,
  errorMessage,
}: {
  type: string;
  name: string;
  label: string;
  placeholder: string;
  value: string | number | undefined;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePhone?: (value: string) => void;
  className?: string;
  error?: boolean;
  errorMessage?: string;
}) {
  return (
    <Wrapper className={error ? `${className} error` : className}>
      <Label htmlFor={name}>{`${label}${required ? '*' : ''}`}</Label>
      {type === 'phone' ? (
        <StyledPhoneInputWithCountrySelect
          defaultCountry="PL"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChangePhone}
          error={error.toString()}
        />
      ) : (
        <StyledInput id={name} type={type} name={name} placeholder={placeholder} value={value} onChange={onChange} required={required} />
      )}

      {error ? <small>{errorMessage}</small> : null}
    </Wrapper>
  );
}

export default Input;

const StyledPhoneInputWithCountrySelect = styled(PhoneInputWithCountrySelect)`
  input {
    font-family: PermianSansTypeface;
    font-size: 18px;
    padding: 18px 24px;
    border: none;
    &:focus {
      outline: var(--accent-color) auto 1px;
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

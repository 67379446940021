import styled from 'styled-components';

function Footer() {
  return <Container>Footer</Container>;
}

export default Footer;

const Container = styled.div`
  background-color: #e0e0e0;
  text-align: center;
  padding: 20px;
`;

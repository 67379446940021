import React from 'react';
import styled from 'styled-components';

function IconButton({ icon, label, onClick }: { icon: JSX.Element; label: string; onClick: () => void }) {
  return (
    <Button onClick={onClick}>
      {icon} {label}
    </Button>
  );
}

export default IconButton;

const Button = styled.button`
  font-family: 'PermianSansTypeface', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  color: var(--accent-color);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

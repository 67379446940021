export function loadDataFromLocalStorage<T>(key: string, setData: (data: T) => void): void {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    try {
      const parsedData: T = JSON.parse(storedData);
      setData(parsedData);
    } catch (error) {
      console.error(`Error parsing data from local storage key: ${key}`, error);
    }
  }
}

import styled from 'styled-components';
import { device } from '../helpers/breakpoints';

function ModalNavigationBar({
  prev,
  next,
  onPrev,
  onNext,
  disabledNext = false,
}: {
  prev?: string;
  next?: string;
  onPrev?: () => void;
  onNext?: () => void;
  disabledNext?: boolean;
}) {
  return prev || next ? (
    <Bar>
      {prev ? (
        <Button className="prev" onClick={onPrev}>
          {prev}
        </Button>
      ) : null}

      {next ? (
        <Button disabled={disabledNext} className="next" onClick={onNext}>
          {next}
        </Button>
      ) : null}
    </Bar>
  ) : null;
}

export default ModalNavigationBar;

const Bar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #e0e0e0;
  padding: 20px 40px;
  gap: 20px;
  position: sticky;
  bottom: 0;
  @media ${device.sm} {
    padding: 20px 10px;
  }
`;
const Button = styled.button`
  width: 236px;
  height: 62px;
  font-family: PermianSansTypeface;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  border: none;
  cursor: pointer;
  &.prev {
    color: var(--text-color);
    background-color: var(--background-color);
  }
  &.next {
    color: var(--background-color);
    background-color: var(--accent-color);
  }
  &:disabled {
    background-color: #d0d0d0;
    color: #a0a0a0;
  }
  @media ${device.sm} {
    width: fit-content;
    padding: 18px 34px;
    margin: auto;
    font-size: 20px;
  }
`;

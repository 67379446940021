import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalContext } from '../contexts/ModalContext';
import { ClientDataType } from '../types/ClientDataType';
import { InvoiceDataType } from '../types/InvoiceDataType';
import { MeetingType } from '../types/meetingType';
import InvoiceView from './StepsContent/InvoiceView';
import MeetingInfoView from './StepsContent/MeetingInfoView';
import SignUpModalView from './StepsContent/SignUpModalView';
import SummaryView from './StepsContent/SummaryView';
import { device } from '../helpers/breakpoints';
import { loadDataFromLocalStorage } from '../helpers/localStorage';

export const CLIENT_DATA_KEY = 'clientData';
export const INVOICE_DATA_KEY = 'invoiceData';

function MeetingModalView({ meetingDetails, onRequestClose }: { meetingDetails: MeetingType; onRequestClose?: () => void }) {
  const [view, setView] = useState('info');
  const [clientData, setClientData] = useState<ClientDataType>();
  const [invoiceData, setInvoiceData] = useState<InvoiceDataType>({
    invoiceRequest: false,
  });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const loadClientDataFromLocalStorage = () => {
    loadDataFromLocalStorage(CLIENT_DATA_KEY, setClientData);
  };

  const loadInvoiceDataFromLocalStorage = () => {
    loadDataFromLocalStorage(INVOICE_DATA_KEY, setInvoiceData);
  };

  useEffect(() => {
    loadClientDataFromLocalStorage();
    loadInvoiceDataFromLocalStorage();
  }, []);

  const getModalContent = () => {
    switch (view) {
      case 'info':
        return <MeetingInfoView />;
      case 'signUp':
        return <SignUpModalView />;
      case 'invoice':
        return <InvoiceView />;
      case 'summary':
        return <SummaryView></SummaryView>;
      default:
        return <div></div>;
    }
  };

  return (
    <ModalContext.Provider
      value={{ view, setView, meetingDetails, onRequestClose, clientData, setClientData, invoiceData, setInvoiceData, formErrors, setFormErrors }}
    >
      <ModalWrapper>{getModalContent()}</ModalWrapper>
    </ModalContext.Provider>
  );
}

export default MeetingModalView;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 802px;
  @media ${device.sm} {
    min-width: calc(100vw - 20px);
    width: calc(100vw - 20px);
  }
`;

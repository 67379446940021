import styled from 'styled-components';
import Input from '../Input';
import { useContext, useEffect } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import { Price } from './SummaryView';
import 'react-phone-number-input/style.css';
import { device } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';
import { CLIENT_DATA_KEY } from '../MeetingModalView';

function SignUpForm({
  emailValidator,
  phoneValidator,
}: {
  emailValidator: (userEmail: string) => boolean;
  phoneValidator: (userPhone: string) => boolean;
}) {
  const { t } = useTranslation('signUp');
  const { meetingDetails, clientData, setClientData, formErrors } = useContext(ModalContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, valueAsNumber, name, value } = e.target;
    if (type === 'range') {
      setClientData({ ...clientData, [name]: valueAsNumber, price: getPrice() });
    } else {
      const updatedData = { ...clientData, [name]: value };
      setClientData(updatedData);
      localStorage.setItem(CLIENT_DATA_KEY, JSON.stringify(updatedData));
    }

    if (name === 'email') {
      emailValidator(value);
    }
  };
  const handlePhoneChange = (value?: string | undefined) => {
    if (value === undefined) return;
    const updatedData = { ...clientData, phone: value };
    setClientData(updatedData);
    localStorage.setItem(CLIENT_DATA_KEY, JSON.stringify(updatedData));
    phoneValidator(value || '');
  };

  useEffect(() => {
    setClientData({ ...clientData, price: getPrice() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData?.seats, clientData?.tables]);
  const getPriceLabel = () => {
    const { max_seats, max_tables, prices } = meetingDetails;
    if (clientData?.seats === 0 && clientData?.tables === 0) return t('selectSeatOrTable');

    if (prices.seat === 0 && prices.table === 0) {
      return t('freeEntrance');
    }
    if (max_seats === 0 && max_tables === 1) {
      return `${t('table')}: ${getPrice()} zł`;
    }
    if (max_seats === 1 && max_tables === 0) {
      return `${t('admission')}: ${getPrice()} zł`;
    }

    return `${t('price')}: ${getPrice()} zł`;
  };

  const getPrice = () => {
    const { prices } = meetingDetails;
    const priceSeats = clientData?.seats ? prices.seat * clientData.seats : 0;
    const priceTables = clientData?.tables ? prices.table * clientData.tables : 0;

    return priceSeats + priceTables;
  };

  const isMeetingPaid = meetingDetails.prices.seat !== 0 && meetingDetails.prices.table !== 0;
  const shouldRenderSeatsSlider = meetingDetails.max_seats > 1;
  const shouldRenderTablesSlider = meetingDetails.max_tables > 1;

  return (
    <Container>
      <div className="detailsRow">
        <Input
          className="small"
          type="text"
          label={t('nameLabel')}
          name="name"
          onChange={handleChange}
          placeholder={t('namePlaceholder')}
          value={clientData?.name || ''}
        />
        <Input
          className="small"
          type="text"
          label={t('surnameLabel')}
          name="surname"
          onChange={handleChange}
          placeholder={t('surnamePlaceholder')}
          value={clientData?.surname || ''}
        />
      </div>
      <Input
        type="text"
        label={t('emailLabel')}
        name="email"
        onChange={handleChange}
        placeholder={t('emailPlaceholder')}
        value={clientData?.email || ''}
        error={formErrors.email ? true : false}
        errorMessage={formErrors.email ? formErrors.email : ''}
        required
      />

      <Input
        type="phone"
        label={t('phoneLabel')}
        name="phone"
        onChangePhone={handlePhoneChange}
        placeholder={t('phonePlaceholder')}
        value={clientData?.phone || ''}
        error={formErrors.phone ? true : false}
        errorMessage={formErrors.phone ? formErrors.phone : ''}
        required
      />

      {isMeetingPaid && (
        <>
          {shouldRenderSeatsSlider && (
            <>
              <label htmlFor="seats">
                {t('numberOfSeats')}: {clientData?.seats}
              </label>
              <input
                type="range"
                id="seats"
                value={clientData?.seats || 0}
                name="seats"
                min={0}
                max={meetingDetails.max_seats}
                onChange={handleChange}
              />
            </>
          )}
          {shouldRenderTablesSlider && (
            <>
              <label htmlFor="tables">
                {t('numberOfTables')}: {clientData?.tables}
              </label>
              <input
                type="range"
                id="tables"
                value={clientData?.tables || 0}
                name="tables"
                min={0}
                max={meetingDetails.max_tables}
                onChange={handleChange}
              />
            </>
          )}
        </>
      )}
      <Price className="right">{getPriceLabel()}</Price>
    </Container>
  );
}

export default SignUpForm;

const Container = styled.div`
  padding: 40px 40px;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .detailsRow {
    display: flex;
    gap: 16px;
  }
  input[type='range'] {
    accent-color: var(--accent-color);
    background-color: white;
  }
  @media ${device.sm} {
    padding: 20px 10px;
    .detailsRow {
      flex-direction: column;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  &:focus-within {
    Label {
      color: var(--accent-color);
    }
  }
  &.small {
    flex-basis: 50%;
  }
  &.error {
    Label {
      color: var(--warning-primary);
    }
    input {
      background-color: var(--warning-bg);
    }
  }
  @media ${device.sm} {
    &.small {
      flex-basis: 100%;
    }
  }
`;

export const Label = styled.label`
  font-family: PermianSansTypeface;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  &.row {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const StyledInput = styled.input`
  font-family: PermianSansTypeface;
  font-size: 18px;
  padding: 18px 24px;
  border: none;
  &:focus {
    outline: var(--accent-color) auto 1px;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

import { useEffect, useState } from 'react';

function BuildInfoPage() {
  const [buildInfo, setBuildInfo] = useState({});
  useEffect(() => {
    fetch('/buildInfo.json')
      .then((response) => response.json())
      .then((json) => setBuildInfo(json));
  }, []);

  return <code>{JSON.stringify(buildInfo)}</code>;
}

export default BuildInfoPage;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MeetingModalView from '../components/MeetingModalView';
import { getPartnerEvent } from '../services/meetingsService';
import { MeetingType } from '../types/meetingType';

const MeetingPage: React.FC = () => {
  const [selectedEvent, setSelectedEvent] = useState<MeetingType | null>(null);

  const location = useLocation();

  const loadData = async (groupId: string, meetingId?: string) => {
    const meeting = await getPartnerEvent(groupId, meetingId);
    setSelectedEvent(meeting);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const groupId = params.get('groupId');
    const meetingId = params.get('meetingId');

    if (groupId) {
      loadData(groupId, meetingId || undefined);
    }
  }, [location]);

  return selectedEvent && <MeetingModalView meetingDetails={selectedEvent} />;
};

export default MeetingPage;

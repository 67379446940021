import { FaMapLocationDot } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';
import { LuCalendarPlus } from 'react-icons/lu';
import styled from 'styled-components';
import { device } from '../helpers/breakpoints';
import { Address } from '../types/Address';
import { typeValues } from '../types/meetingType';
import CalendarCard from './CalendarCard';
import IconButton from './IconButton';

function ModalHeader({
  start_date,
  title,
  group_name,
  group_color,
  onRequestClose,
  type,
  address,
  link,
}: {
  start_date: string;
  title: string;
  group_name: string;
  group_color?: string;
  type: typeValues;
  address?: Address;
  link?: string;
  onRequestClose?: () => void;
}) {
  return (
    <DetailsWrapper>
      <div className="wrappper">
        <div className="wrappper">
          <CalendarCard date={start_date} />
          <Title group_color={group_color}>
            {title}
            <p>{group_name}</p>
          </Title>
        </div>
        {onRequestClose ? <StyledCloseButton onClick={onRequestClose} size={35} /> : null}
      </div>
      <AddressDetails>
        {type === 'stationary' ? (
          address && (
            <>
              <MeetingInfo>{address.name}</MeetingInfo>
              <MeetingAddress>{`ul. ${address.street} ${address.number}, ${address.postal_code} ${address.city}`}</MeetingAddress>
              <ButtonsWrapper>
                <IconButton label="pokaż na mapie" icon={<FaMapLocationDot size={20} />} onClick={() => {}} />
                <IconButton label="dodaj do kalendarza" icon={<LuCalendarPlus size={20} />} onClick={() => {}} />
              </ButtonsWrapper>
            </>
          )
        ) : (
          <>
            <MeetingInfo>Online</MeetingInfo>
            {link && <MeetingAddress>{link}</MeetingAddress>}
            <ButtonsWrapper>
              <IconButton label="dodaj do kalendarza" icon={<LuCalendarPlus size={20} />} onClick={() => {}} />
            </ButtonsWrapper>
          </>
        )}
      </AddressDetails>
    </DetailsWrapper>
  );
}

export default ModalHeader;

const DetailsWrapper = styled.div`
  background-color: #ffffff;
  padding: 35px 40px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
  div.wrappper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  @media ${device.sm} {
    padding: 15px 10px;
  }
`;

const StyledCloseButton = styled(IoMdClose)`
  cursor: pointer;
`;
const Title = styled.h2<{ group_color?: string }>`
  font-family: 'PermianSansTypeface', sans-serif;
  font-size: 32px;
  line-height: 1.38;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    display: flex;
    align-items: center;
    color: var(--accent-color);
    font-weight: 700;
    font-size: 24px;
    line-height: 1.08;
    &:before {
      content: '';
      margin-right: 7px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: ${({ group_color }) => (group_color ? group_color : 'var(--accent-color)')};
    }
  }
  @media ${device.sm} {
    font-size: 16px;
    p {
      font-size: 14px;
    }
  }
`;

const AddressDetails = styled.div`
  font-family: 'PermianSansTypeface', sans-serif;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const MeetingInfo = styled.p`
  font-size: 20px;
  font-weight: 700;
`;
const MeetingAddress = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  line-height: 1.75;
`;

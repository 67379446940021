import { useContext } from 'react';
import Input from '../Input';
import styled from 'styled-components';
import { ModalContext } from '../../contexts/ModalContext';
import { device } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';
import { INVOICE_DATA_KEY } from '../MeetingModalView';

function InvoiceFields({
  postalCodeValidator,
  nipValidator,
  requiredFieldValidator,
}: {
  postalCodeValidator: (postalCode: string) => boolean;
  nipValidator: (nip: string) => boolean;
  requiredFieldValidator: (requiredField: string, value: string) => boolean;
}) {
  const { t } = useTranslation(['invoice']);
  const { invoiceData, setInvoiceData, formErrors } = useContext(ModalContext);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedData = { ...invoiceData, [name]: value };
    setInvoiceData(updatedData);
    localStorage.setItem(INVOICE_DATA_KEY, JSON.stringify(updatedData));

    if (name === 'postalCode') postalCodeValidator(value);
    else if (name === 'nip') nipValidator(value);
    else {
      requiredFieldValidator(name, value);
    }
  };

  return (
    <>
      <Input
        type="text"
        label={t('companyLabel')}
        name="companyName"
        onChange={handleChange}
        placeholder={t('companyPlaceholder')}
        value={invoiceData?.companyName}
        error={formErrors.companyName ? true : false}
        errorMessage={formErrors.companyName ? formErrors.companyName : ''}
      />
      <Input
        type="text"
        label={t('nipLabel')}
        name="nip"
        onChange={handleChange}
        placeholder={t('nipPlaceholder')}
        value={invoiceData?.nip || undefined}
        error={formErrors.nip ? true : false}
        errorMessage={formErrors.nip ? formErrors.nip : ''}
      />
      <Input
        type="text"
        label={t('streetLabel')}
        name="street"
        onChange={handleChange}
        placeholder={t('streetPlaceholder')}
        value={invoiceData?.street}
        error={formErrors.street ? true : false}
        errorMessage={formErrors.street ? formErrors.street : ''}
      />
      <DetailsRow>
        <Input
          className="small"
          type="text"
          label={t('postalCodeLabel')}
          name="postalCode"
          onChange={handleChange}
          placeholder={t('postalCodePlaceholder')}
          value={invoiceData?.postalCode}
          error={formErrors.postalCode ? true : false}
          errorMessage={formErrors.postalCode ? formErrors.postalCode : ''}
        />
        <Input
          className="small"
          type="text"
          label={t('cityLabel')}
          name="city"
          onChange={handleChange}
          placeholder={t('cityPlaceholder')}
          value={invoiceData?.city}
          error={formErrors.city ? true : false}
          errorMessage={formErrors.city ? formErrors.city : ''}
        />
      </DetailsRow>
    </>
  );
}

export default InvoiceFields;

const DetailsRow = styled.div`
  display: flex;
  gap: 24px;
  @media ${device.sm} {
    flex-direction: column;
  }
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUniqueGroupNamesWithIds, getUniqueLocations } from '../../services/meetingsService';
import { device } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';

function SearchNav() {
  const { t } = useTranslation('navigation', { keyPrefix: 'searchNav' });
  const [groups, setGroups] = useState<{ id: string; name: string }[]>([]);
  const [locations, setLocations] = useState<string[]>([]);

  useEffect(() => {
    async function loadData() {
      const groups = await getUniqueGroupNamesWithIds();
      const locations = await getUniqueLocations();
      setGroups(groups);
      setLocations(locations);
    }
    loadData();
  }, []);

  return (
    <SearchBar>
      <Box className="searchbar">
        <label>{t('search')}</label>
        <input placeholder={t('searchPlaceholder')}></input>
      </Box>

      <Box className="group">
        <label>{t('groups')}</label>
        <select>
          <option>{t('allGroups')}</option>
          {groups.map((group) => (
            <option key={group.id}>{group.name}</option>
          ))}
        </select>
      </Box>
      <Box className="location">
        <label>{t('localization')}</label>
        <select>
          <option>{t('allPlaces')}</option>
          {locations.map((location) => (
            <option key={location}>{location}</option>
          ))}
        </select>
      </Box>

      <Button>{t('buttonLabel')}</Button>
    </SearchBar>
  );
}

export default SearchNav;

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: end;
  flex-wrap: wrap;
  @media ${device.lg} {
    justify-content: space-between;
  }
`;
const Button = styled.button`
  font-family: PermianSansTypeface;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  padding: 18px 65px;
  background-color: var(--accent-color);
  border: none;
  color: white;
  height: 62px;
  cursor: pointer;
  @media ${device.lg} {
    flex-basis: 25%;
  }
  @media ${device.md} {
    padding: 18px 24px;
  }
`;
const Box = styled.div<{ minWidth?: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 92px;
  font-family: PermianSansTypeface;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  width: fit-content;
  &:first-child {
    flex-grow: 1;
  }
  input,
  select {
    height: 62px;
    border: none;
    padding: 18px 24px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    &:focus {
      outline: none;
    }
  }
  label {
    font-weight: bold;
  }
  @media ${device.lg} {
    input,
    select {
      padding: 18px 12px;
    }
    &.searchbar {
      flex-basis: 100%;
    }
    &.group,
    &.location {
      flex-basis: calc(35% - 16px);
    }
  }
  @media ${device.sm} {
    &.group,
    &.location {
      flex-basis: 100%;
    }
  }
`;

import styled from 'styled-components';
import ModalHeader from '../ModalHeader';
import { ModalContext } from '../../contexts/ModalContext';
import { useContext, useEffect } from 'react';
import ModalNavigationBar from '../ModalNavigationBar';
import SignUpForm from './SignUpForm';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { device } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';

function SignUpModalView(): JSX.Element {
  const { t } = useTranslation(['validation', 'navigation']);
  const { meetingDetails, onRequestClose, setView, formErrors, clientData, setClientData, setFormErrors } = useContext(ModalContext);

  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (meetingDetails.max_seats === 0 && meetingDetails.max_tables === 1) {
      setClientData({ ...clientData, seats: 0, tables: 1, paymentType: 'online' });
    } else {
      setClientData({ ...clientData, seats: clientData?.seats || 1, tables: clientData?.tables || 0, paymentType: 'online' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrev = () => {
    setView('info');
  };
  const onNext = () => {
    if (clientData?.email === undefined || clientData?.phone === undefined) {
      let errors = { ...formErrors };
      if (clientData?.email === undefined) {
        errors.email = t('requiredField');
      }
      if (clientData?.phone === undefined) {
        errors.phone = t('requiredField');
      }
      setFormErrors(errors);
      return;
    }

    if (clientData.price === 0) {
      setView('summary');
      return;
    }
    setView('invoice');
  };
  const validateEmail = (userEmail: string) => {
    if (userEmail === undefined) return false;
    if (userEmail.length === 0) {
      setFormErrors({ ...formErrors, email: t('requiredField') });
      return false;
    }
    if (!EMAIL_REGEX.test(userEmail)) {
      setFormErrors({ ...formErrors, email: t('validEmail') });
      return false;
    }
    let errors = { ...formErrors };
    delete errors.email;
    setFormErrors(errors);
    return true;
  };
  const validatePhone = (userPhone: string) => {
    if (userPhone === undefined) {
      setFormErrors({ ...formErrors, phone: t('requiredField') });
      return false;
    }
    if (!isValidPhoneNumber(userPhone)) {
      setFormErrors({ ...formErrors, phone: t('validPhone') });
      return false;
    }
    let errors = { ...formErrors };
    delete errors.phone;
    setFormErrors(errors);
    return true;
  };

  return (
    <ModalWrapper>
      <ModalHeader {...meetingDetails} onRequestClose={onRequestClose} />
      <SignUpForm phoneValidator={validatePhone} emailValidator={validateEmail} />
      <ModalNavigationBar
        prev={t('prev', { ns: 'navigation' })}
        onPrev={onPrev}
        next={t('next', { ns: 'navigation' })}
        onNext={onNext}
        disabledNext={Object.keys(formErrors).length >= 1 ? true : false}
      />
    </ModalWrapper>
  );
}

export default SignUpModalView;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 802px;
  @media ${device.sm} {
    min-width: calc(100vw - 20px);
    width: calc(100vw - 20px);
  }
`;

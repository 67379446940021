import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import { ModalContext } from '../../contexts/ModalContext';
import { device } from '../../helpers/breakpoints';
import ModalHeader from '../ModalHeader';
import ModalNavigationBar from '../ModalNavigationBar';

function MeetingInfoView() {
  const { t } = useTranslation('navigation');
  const { meetingDetails, onRequestClose, setView } = useContext(ModalContext);
  const [nextLabel, setNextLabel] = useState(t('signUp') as string);

  const onNext = () => {
    if (nextLabel === t('signUp')) setView('signUp');
  };

  useEffect(() => {
    if (new Date(meetingDetails?.start_date) <= new Date()) {
      setNextLabel(t('pastEvent'));
    }
  }, [meetingDetails?.start_date, t]);

  return (
    <>
      <ModalImage src={meetingDetails?.header_image_url ? '{header_image_url}' : '/modalBg.jpg'} alt="meeting" />
      <ModalHeader {...meetingDetails} onRequestClose={onRequestClose} />
      <Description>
        <Markdown>{meetingDetails.description}</Markdown>
      </Description>
      <ModalNavigationBar next={nextLabel} onNext={onNext} />
    </>
  );
}

export default MeetingInfoView;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 330px;
  object-fit: cover;
  object-position: top;
`;

const Description = styled.div`
  background-color: #e0e0e0;
  padding: 20px 45px 25px 45px;
  font-size: 16px;
  line-height: 1.75;
  max-width: 100%;
  code {
    white-space: normal;
  }
  p {
    margin: 0;
  }
  @media ${device.sm} {
    padding: 20px 10px 25px 10px;
  }
`;

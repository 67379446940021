import styled from 'styled-components';
import { device } from '../helpers/breakpoints';

function CalendarCard({ date }: { date: string }) {
  const dateObj = new Date(date);
  return (
    <Wrapper>
      <p>{dateObj.getDate()}</p>
      <span>{dateObj.toLocaleString(navigator.language, { month: 'long' })}</span>
    </Wrapper>
  );
}

export default CalendarCard;

const Wrapper = styled.div`
  width: 75px;
  min-width: 75px;
  height: 75px;
  background-color: var(--accent-color);
  display: flex;
  flex-direction: column;
  color: var(--background-color);
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'PermianSansTypeface', sans-serif;
  p {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  span {
    font-size: 12px;
    line-height: 1.33;
  }
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 20px;
    background-color: white;
    bottom: -7px;
    right: -2px;
    transform: rotate(45deg);
  }
  @media ${device.sm} {
    min-width: 60px;
    width: 60px;
    height: 75px;
    p {
      font-size: 34px;
    }
    span {
      font-size: 10px;
    }
  }
`;

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';

function Banner() {
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Container>
      <Slider {...settings}>
        <div>
          <h3>Banner 1</h3>
        </div>
        <div>
          <h3>Banner 2</h3>
        </div>
        <div>
          <h3>Banner 3</h3>
        </div>
      </Slider>
    </Container>
  );
}

export default Banner;

const Container = styled.div`
  padding: 0 0 40px 0;
  div {
    background-color: #efefef;
    height: 100px;
    display: flex;
    text-align: center;
  }
`;

import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import BuildInfoPage from './pages/BuildInfoPage';
import CalendarPage from './pages/CalendarPage';
import MeetingPage from './pages/MeetingPage';

function App() {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.data &&
        typeof event.data.colorScheme === 'object' &&
        'text' in event.data.colorScheme &&
        'bg' in event.data.colorScheme &&
        'accent' in event.data.colorScheme
      ) {
        const { text, bg, accent } = event.data.colorScheme as { text: string; bg: string; accent: string };
        document.documentElement.style.setProperty('--text-color', text || '#000000');
        document.documentElement.style.setProperty('--background-color', bg || '#f8f9fa');
        document.documentElement.style.setProperty('--accent-color', accent || '#2ecc71');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<CalendarPage />} />
      <Route path="/buildInfo" element={<BuildInfoPage />} />
      <Route path="/meeting" element={<MeetingPage />} />
    </Routes>
  );
}

export default App;

import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalContext } from '../../contexts/ModalContext';
import ModalHeader from '../ModalHeader';
import ModalNavigationBar from '../ModalNavigationBar';
import { Label } from './SignUpForm';
import Input from '../Input';
import { device } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';

function SummaryView() {
  const { t } = useTranslation(['summary', 'navigation']);
  const { meetingDetails, onRequestClose, setView, clientData, invoiceData, setClientData } = useContext(ModalContext);

  const [showPromoCode, setShowPromoCode] = React.useState(clientData?.promoCode ? true : false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };
  const onPrev = () => {
    if (clientData?.price === 0) setView('signUp');
    else setView('invoice');
  };
  const onNext = () => {
    localStorage.clear();
    console.log('Przejście do płatności');
  };
  const handlePromotionCode = () => {
    console.log('Użycie kodu rabatowego');
  };

  return (
    <>
      <ModalHeader {...meetingDetails} onRequestClose={onRequestClose} />
      <Container>
        <Header>{t('summary')}</Header>
        <InformationRow>
          <Label>{t('buyerDetails')}: </Label>
          {clientData?.name} {clientData?.surname}, {clientData?.email}, {clientData?.phone}
        </InformationRow>
        <InformationRow>{clientData?.seats ? `${t('numberOfSeats')}: ${clientData.seats}` : ''}</InformationRow>
        <InformationRow>{clientData?.tables ? `${t('numberOfTables')}: ${clientData.tables}` : ''}</InformationRow>
        {invoiceData?.invoiceRequest && (
          <InformationRow>
            <Label>{t('invoiceDetails')}:</Label> {invoiceData.companyName} - {invoiceData.nip}
            <br />
            {invoiceData.street}, {invoiceData.postalCode}, {invoiceData.city}.
          </InformationRow>
        )}
        <InformationRow>
          <Label>{t('total')}:</Label>
          <Price className="right">{clientData?.price} zł</Price>
          <PromotionCodeSection>
            <div onClick={() => setShowPromoCode((prev) => !prev)}>{!showPromoCode ? t('addPromotionalCode') : t('hide')}</div>
            {showPromoCode && (
              <PromotionContainer>
                <Input
                  type="text"
                  value={clientData?.promoCode ?? ''}
                  onChange={handleChange}
                  name={'promoCode'}
                  label={''}
                  placeholder={t('promoCodeLabel')}
                />
                <button onClick={handlePromotionCode}>{t('useCode')}</button>
              </PromotionContainer>
            )}
          </PromotionCodeSection>
        </InformationRow>
        <PaymentSection>
          <PaymentType>
            <Label>{t('paymentLabel')}</Label>
            <Label className="row">
              <input
                type="radio"
                id="paymentType"
                name="paymentType"
                value="online"
                onChange={handleChange}
                checked={clientData?.paymentType === 'online'}
              />
              {t('paymentMethods.online')}
            </Label>
            <Label className="row">
              <input
                type="radio"
                id="paymentType"
                name="paymentType"
                value="inPerson"
                onChange={handleChange}
                checked={clientData?.paymentType === 'inPerson'}
              />
              {t('paymentMethods.inPerson')}
            </Label>
          </PaymentType>
        </PaymentSection>
      </Container>
      <ModalNavigationBar prev={t('prev', { ns: 'navigation' })} onPrev={onPrev} next={t('pay', { ns: 'navigation' })} onNext={onNext} />
    </>
  );
}

export default SummaryView;

const Container = styled.div`
  padding: 20px 40px;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.sm} {
    padding: 20px 10px;
  }
`;
const Header = styled.h2`
  font-family: PermianSansTypeface;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  margin: 5px 0 10px 0;
  @media ${device.sm} {
    font-size: 20px;
  }
`;
const InformationRow = styled.div``;
const PaymentSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-end;
  input[type='radio'] {
    width: 24px;
    height: 24px;
    accent-color: var(--accent-color);
    background-color: white;
    @media ${device.sm} {
      width: 16px;
      height: 16px;
    }
  }
`;
const PaymentType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Price = styled.div`
  font-family: PermianSansTypeface;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  color: var(--accent-color);
  &.right {
    text-align: right;
  }
`;
const PromotionCodeSection = styled.div`
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
    cursor: pointer;
  }
`;
const PromotionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  button {
    font-family: PermianSansTypeface;
    background-color: var(--accent-color);
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

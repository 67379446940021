import styled from 'styled-components';
import SearchNav from './SearchNav';
import { useTranslation } from 'react-i18next';

function MainNav() {
  const { t } = useTranslation('navigation');

  return (
    <Navigation>
      <Links>
        <Link href="https://przezrekomendacje.pl/" target="__blank" color={'var(--accent-color)'}>
          przezrekomendacje.pl
        </Link>
        <Link>{t('login')}</Link>
      </Links>
      <SearchNav />
    </Navigation>
  );
}

export default MainNav;

const Navigation = styled.div`
  padding: 16px 10vw;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Links = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Link = styled.a`
  font-size: 16px;
  font-weight: bold;
  font-family: PermianSansTypeface;
  color: ${({ color }) => color || 'black'};
  text-decoration: none;
`;

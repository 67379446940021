import styled from 'styled-components';
import { Label } from './SignUpForm';
import { useTranslation } from 'react-i18next';

function InvoiceInput({ value, handleChange }: { value: boolean; handleChange: () => void }) {
  const { t } = useTranslation('invoice');

  return (
    <>
      <InvoiceRow onChange={handleChange}>
        <Label>{t('invoiceRequest')}</Label>
        <Switch>
          <Checkbox checked={value} type="checkbox" id="invoice" name="invoice" readOnly />
          <Slider />
        </Switch>
      </InvoiceRow>
    </>
  );
}

export default InvoiceInput;

const InvoiceRow = styled.label`
  margin-top: 8px;
  display: flex;
  background-color: #e0e0e0;
  padding: 18px 20px;
  cursor: pointer;
  justify-content: space-between;
`;

const Switch = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${Slider} {
    background-color: var(--accent-color);
  }
  &:focus + ${Slider} {
    box-shadow: 0 0 1px var(--accent-color);
  }
  &:checked + ${Slider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

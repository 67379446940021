import React, { useContext, useState } from 'react';
import ModalHeader from '../ModalHeader';
import { ModalContext } from '../../contexts/ModalContext';
import InvoiceInput from './InvoiceInput';
import ModalNavigationBar from '../ModalNavigationBar';
import styled from 'styled-components';
import InvoiceFields from './InvoiceFields';
import { InvoiceDataType } from '../../types/InvoiceDataType';
import { device } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';

function InvoiceView() {
  const { t } = useTranslation(['validation', 'navigation']);
  const { meetingDetails, onRequestClose, setView, invoiceData, setInvoiceData, formErrors, setFormErrors } = useContext(ModalContext);
  const [isInvoiceRequested, setIsInvoiceRequested] = useState(invoiceData.invoiceRequest);

  const POSTAL_CODE_REGEX = /^(PL)?\d{2}[- ]?\d{3}$/;
  const NIP_REGEX = /^(PL)?\d{3}[- ]?\d{3}[- ]?\d{2}[- ]?\d{2}$|^(PL)?\d{3}[- ]?\d{2}[- ]?\d{2}[- ]?\d{3}$|^(PL)?\d{10}$/;
  const REQUIRED_FIELDS = ['companyName', 'nip', 'street', 'postalCode', 'city'];

  const handleInvoiceRequest = () => {
    setIsInvoiceRequested(!isInvoiceRequested);
    setInvoiceData({ ...invoiceData, invoiceRequest: !isInvoiceRequested });
  };
  const onPrev = () => {
    setView('signUp');
  };
  const onNext = () => {
    if (isInvoiceRequested) {
      let errors = { ...formErrors };
      REQUIRED_FIELDS.forEach((field) => {
        if (invoiceData[field as keyof InvoiceDataType] === undefined) {
          errors[field] = t('requiredField', { ns: 'validation' });
          setFormErrors(errors);
        }
      });
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
    setView('summary');
  };

  const validateRequiredField = (requiredFieldName: string, value: string) => {
    if (value === '') {
      setFormErrors({ ...formErrors, [requiredFieldName]: t('requiredField') });
      return false;
    }
    let errors = { ...formErrors };
    delete errors[requiredFieldName];
    setFormErrors(errors);
    return true;
  };

  const validatePostalCode = (postalCode: string) => {
    if (postalCode === undefined) {
      setFormErrors({ ...formErrors, postalCode: t('requiredField') });
      return false;
    }
    if (!POSTAL_CODE_REGEX.test(postalCode)) {
      setFormErrors({ ...formErrors, postalCode: t('validPostalCode') });
      return false;
    }
    let errors = { ...formErrors };
    delete errors.postalCode;
    setFormErrors(errors);
    return true;
  };

  const validateNIP = (nip: string) => {
    if (nip === undefined) {
      setFormErrors({ ...formErrors, nip: t('requiredField') });
      return false;
    }
    if (!NIP_REGEX.test(nip.toString())) {
      setFormErrors({ ...formErrors, nip: t('validNip') });
      return false;
    }
    let errors = { ...formErrors };
    delete errors.nip;
    setFormErrors(errors);
    return true;
  };

  return (
    <>
      <ModalHeader {...meetingDetails} onRequestClose={onRequestClose} />
      <Container>
        <InvoiceInput value={isInvoiceRequested} handleChange={handleInvoiceRequest} />
        {isInvoiceRequested && (
          <InvoiceFields postalCodeValidator={validatePostalCode} nipValidator={validateNIP} requiredFieldValidator={validateRequiredField} />
        )}
      </Container>
      <ModalNavigationBar prev={t('prev', { ns: 'navigation' })} onPrev={onPrev} next={t('next', { ns: 'navigation' })} onNext={onNext} />
    </>
  );
}

export default InvoiceView;

const Container = styled.div`
  padding: 20px 40px;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  gap: 16px;
  input[type='radio'] {
    width: 24px;
    height: 24px;
    accent-color: var(--accent-color);
    background-color: white;
  }
  .detailsRow {
    display: flex;
    gap: 16px;
  }
  @media ${device.sm} {
    padding: 20px 10px;
  }
`;
